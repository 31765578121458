export const useSharedState = () => {
    const navs = useState('navs', () => [])
    const globals = useState('globals', () => [])
    const alternateUrls = useState('alternateUrls', () => [])
    const translations = useState('translations', () => {})
    const groups = useState('groups', () => [{
        id: 2,
        name: 'Canada',
    }])
    const countryBannerOpened = useState('countryBannerOpened', () => false)
    const langCookie = useCookie('i18n_redirected')
    const setLangCookie = (lang) => {
        langCookie.value = lang
        refreshCookie('i18n_redirected')
    }
    const langChosenCookie = useCookie('i18n_chosen')
    const setLangChosenCookie = (lang) => {
        langChosenCookie.value = lang
        refreshCookie('i18n_chosen')
    }
    const olnuf = useCookie('olnuf', { default: () => false })
    const setOlnuf = (val) => {
        if (isSEO()) {
            olnuf.value = true
        }
        else {
            olnuf.value = val
        }
        refreshCookie('olnuf')
    }
    const sites = useState('sites', () => ({
        fr_CA: {
            sort: 1,
            id: 3,
            name: 'Québec',
            lang: 'fr_CA',
            shortlang: 'fr',
            primary: false,
            prefix: 'fr-ca',
            metalang: 'fr-ca',
            groupId: 5,
        },
        en_CA: {
            sort: 2,
            id: 2,
            name: 'Canada',
            lang: 'en_CA',
            shortlang: 'en',
            metalang: 'en-ca',
            primary: true,
            prefix: '',
            groupId: 2,
        },
        en_US: {
            sort: 3,
            id: 4,
            name: 'United States',
            lang: 'en_US',
            shortlang: 'en',
            primary: false,
            prefix: 'en-us',
            metalang: 'en-us',
            groupId: 4,
        },
        en_GB: {
            sort: 4,
            id: 7,
            name: 'International',
            lang: 'en_GB',
            shortlang: 'en',
            primary: false,
            prefix: 'en-gb',
            metalang: 'en-gb',
            groupId: 3,
        },
        fr_FR: {
            sort: 5,
            id: 6,
            name: 'International',
            lang: 'fr_FR',
            shortlang: 'fr',
            primary: false,
            prefix: 'fr-fr',
            metalang: 'fr-fr',
            groupId: 3,
        },
    }))
    const getSiteByMetaLang = (metalang) => {
        let site = null
        const siteKeys = Object.keys(sites.value)
        for (let i = 0, il = siteKeys.length; i < il; ++i) {
            const siteKey = siteKeys[i]
            if (sites.value[siteKey].metalang === metalang) {
                site = sites.value[siteKey]
            }
        }
        return site
    }

    const { setLocaleCookie } = useI18n()
    const activeSite = useState('activeSite', () => sites.value.en_CA)
    const currentEntry = useState('currentEntry', () => null)
    const switchActiveSite = async (siteMetaLang, force = false) => {
        setLangChosenCookie(siteMetaLang)
        setLocaleCookie(siteMetaLang)
        const site = getSiteByMetaLang(siteMetaLang)
        const siteHome = ('/'+site.prefix).replace(/\/?$/, '/');

        /**
         * Regex replace to add trailing slash if it's missing
         */
        if (force === true) {
            navigateTo(siteHome)
        }
        else if (activeSite.value.metalang !== siteMetaLang) {
            navigateTo(siteHome)
        }
        countryBannerOpened.value = false
    }

    const setNavs = (navsData = []) => {
        navs.value = navsData
    }
    const setGlobals = (globalsData = []) => {
        globals.value = globalsData

        const res = {}
        if (globalsData?.translations?.ui) {
            for (let i = 0, il = globalsData?.translations.ui.length; i < il; ++i) {
                res[globalsData?.translations?.ui[i].label] = globalsData?.translations.ui[i].translation
            }
        }
        translations.value = res
    }

    const setAlternateUrls = (alternateUrlsData = []) => {
        const alts = toRaw(alternateUrlsData)
        const res = []
        for (let i = 0, il = alts.length; i < il; ++i) {
            const alternateUrl = alts[i]
            // Condition qui permet de n'afficher que les URLs des sites du même groupe (groupe région dans notre cas)
            if (activeSite.value.groupId === sites.value[alternateUrl.siteHandle].groupId) {
                alternateUrl.fixed = alternateUrl.url
                res.push(toRaw(alternateUrl))
            }
        }
        alternateUrls.value = res
    }
    const setGroups = (groupsData = []) => {
        groups.value = groupsData
    }
    const setSites = (sitesData = {}) => {
        sites.value = sitesData
    }
    const setActiveSite = (activeSiteData = {}) => {
        activeSite.value = activeSiteData
    }
    const setCurrentEntry = (currentEntryData = null) => {
        currentEntry.value = currentEntryData
        if (currentEntry.value?.localized) {
            setAlternateUrls(currentEntry.value.localized)
        }
    }
    /**
     * Map SEO data fetched from SEOMatic through graphql with the useSeoMeta format
     */
    const setCurrentSeo = (seoData = null)  => {
        if(seoData) {
            // console.log(JSON.parse(seoData.metaLinkContainer));
            // formattedData.charset = 'utf-8';
            // formattedData.title = JSON.parse(seoData.metaTitleContainer)[0].title;
            // formattedData.ogTitle = formattedData.title;
            // formattedData.description = JSON.parse(seoData.metaDescriptionContainer)[0].description;
            // formattedData.ogDescription = formattedData.description;
            // formattedData.canonical = JSON.parse(seoData.metaLinkContainer)[0].canonical;
            // formattedData.ogCanonical = JSON.parse(seoData.metaLinkContainer)[0].canonical;
            // formattedData.locale = JSON.parse(seoData.metaLinkContainer)[0].locale;
            // formattedData.ogLocale = JSON.parse(seoData.metaLinkContainer)[0].locale;
            
            let metaTitleContainerFormatted = '';
            const metaTitleContainer = JSON.parse(seoData.metaTitleContainer);
            metaTitleContainerFormatted = metaTitleContainer?.title?.title;

            let metaTagContainerFormatted = [];
            const metaTagContainer = JSON.parse(seoData.metaTagContainer);
            const metaTagContainerKeys = Object.keys(metaTagContainer);
            for (let i=0, il=metaTagContainerKeys.length; i < il; ++i) {
                let metaKey = metaTagContainerKeys[i];
                if(!Array.isArray(metaTagContainer[metaKey]) && Object.values(metaTagContainer[metaKey]).length > 0) {
                    metaTagContainerFormatted.push(metaTagContainer[metaKey]);
                }
            }

            let metaLinkContainerFormatted = [];
            const metaLinkContainer = JSON.parse(seoData.metaLinkContainer);
            const metaLinkContainerKeys = Object.keys(metaLinkContainer);
            for (let i=0, il=metaLinkContainerKeys.length; i < il; ++i) {
                let metaKey = metaLinkContainerKeys[i];
                if(!Array.isArray(metaLinkContainer[metaKey]) && Object.values(metaLinkContainer[metaKey]).length > 0) {
                    metaLinkContainerFormatted.push(metaLinkContainer[metaKey]);
                }
            }


            let metaScriptContainerFormatted = [];
            const metaScriptContainer = JSON.parse(seoData.metaScriptContainer);
            const metaScriptContainerKeys = Object.keys(metaScriptContainer);
            for (let i=0, il=metaScriptContainerKeys.length; i < il; ++i) {
                let metaKey = metaScriptContainerKeys[i];
                if(!Array.isArray(metaScriptContainer[metaKey]) && Object.values(metaScriptContainer[metaKey]).length > 0) {
                    metaScriptContainerFormatted.push(metaScriptContainer[metaKey]);
                }
            }


            useHead({
                link:metaLinkContainerFormatted,
                meta:metaTagContainerFormatted,
                script:metaScriptContainerFormatted,
                title:metaTitleContainerFormatted,
            })
        }
    }
    const tt = (label = '') => {
        let res = ''
        if (label && typeof translations.value[label] !== 'undefined') {
            res = translations.value[label]
        }
        else {
            res = label
        }

        return res
    }

    const countryBannerVisible = computed(() => {
        let res = true
        if (countryBannerOpened.value === true) {
            res = true
        }
        else if (langChosenCookie.value) {
            res = false
        }
        else if (isSEO()) {
            res = false
        }
        return res
    })

    return {
        langCookie,
        setLangCookie,
        langChosenCookie,
        setLangChosenCookie,
        olnuf,
        setOlnuf,
        countryBannerVisible,
        navs,
        globals,
        alternateUrls,
        sites,
        activeSite,
        groups,
        currentEntry,
        translations,
        setNavs,
        setGlobals,
        setAlternateUrls,
        setGroups,
        setSites,
        setActiveSite,
        setCurrentEntry,
        tt,
        getSiteByMetaLang,
        switchActiveSite,
        countryBannerOpened,
        setCurrentSeo,
    }
}
