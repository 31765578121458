import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { SplitText } from 'gsap/SplitText'
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'

if (!isServer()) {
    // gsap.registerPlugin(CustomEase, SplitText, ScrambleTextPlugin, ScrollTrigger)
    gsap.registerPlugin(CustomEase, SplitText, ScrambleTextPlugin)
    gsap.config({ force3D: true, autoSleep: 2400 })
    CustomEase.create('cubic', '.43,.17,.38,1')
    gsap.defaults({ ease: 'cubic' })
}

export {
    // gsap, SplitText, CustomEase, ScrollTrigger, 
    gsap, SplitText, CustomEase, 
}
