<!-- eslint-disable no-param-reassign -->
<script setup>
import '~/assets/css/main.css'
import { animate, stagger } from 'motion'

const {
    setWindowSizes,
    scrollTop,
    navOpened,
    subnavOpened,
    submenuOpened,
    loaded,
    loadingShown,
    debouncedResize,
    calculateLenisScroll,
    calculateWindowScroll,
} = useClientState()
const {
    setNavs,
    setGlobals,
    activeSite,
    setActiveSite,
    sites,
} = useSharedState()

const route = useRoute()
const router = useRouter()
const lenis = useLenis()

// Lenis only gets created on client-side
// if (!isServer()) {
onNuxtReady(() => {
    if (lenis.value) {
        calculateLenisScroll()
        lenis.value.on('scroll', calculateLenisScroll)
    }
    else {
        calculateWindowScroll()
        window.addEventListener('scroll', calculateWindowScroll, false)
    }

    ['resize', 'orientationchange'].forEach((evname) => {
        window.addEventListener(evname, debouncedResize)
    })
})
// }

if (route?.query?.token && route?.query?.['x-craft-live-preview']) {
    useGqlHost(`?x-craft-live-preview=${route.query['x-craft-live-preview']}&token=${route.query.token}`)
}

router.beforeEach(() => {
    navOpened.value = false
    subnavOpened.value = false
    submenuOpened.value = null
    loadingShown.value = true;
})

// router.afterEach((to, from) => {
//     if (to.path !== from.path && to.hash) {
//         const targetElement = document.querySelector(to.hash);
//         if (targetElement) {
//             lenisScrollTo(targetElement, {
//                 onComplete: next,
//             });
//         }
//     }
// });

// Unable to make this reactive directly in store?
watchEffect(() => {
    const path = route?.path ?? ''
    const detectedSite = detectSiteFromPath(path, sites.value) ?? sites.value.en_CA
    setActiveSite(detectedSite)
})

const offsetClasses = ref('[--geloso-offset-top:7rem] xl:[--geloso-offset-top:10.25rem]')
// @todo:  3.5rem offset bug en accueil???

const pageTransitionWrapper = ref(null)
const pageTransitions = {
    name: 'geloso-page',
    mode: 'out-in',
    css: false,
    onBeforeLeave: () => {
        const layers = pageTransitionWrapper.value.querySelectorAll('.page-transition-layer')
        pageTransitionWrapper.value.style.transform = 'translateY(0%) translateZ(0)'
        for (let i = 0, il = layers.length; i < il; ++i) {
            const layer = layers[i]
            layer.style.transform = 'translateY(120%)'
        }
    },
    onLeave: (el, done) => {
        const layers = pageTransitionWrapper.value.querySelectorAll('.page-transition-layer')
        animate(layers, {
            transform: ['translateY(120%) translateZ(0)', 'translateY(0%) translateZ(0)'],
        }, {
            duration: 1,
            delay: stagger(0.2), // duration + stagger = 1195ms
            easing: [0.14, 0.47, 0.45, 0.94],
        })

        // Resolve just a bit earlier to try to have the page be the "3rd" layer with the same timing
        window.setTimeout(() => {
            done()
        }, 1100)
    },
    onBeforeEnter: (el) => {
        el.style.position = 'absolute'
        el.style.transform = 'translateY(0vh) translateZ(0vh)'
        el.style.left = '0'
        // el.style.top = 'calc(var(--geloso-offset-top) - 3.5rem)';
        // el.style.paddingTop = 'var(--geloso-offset-top)';
        el.style.top = 'var(--geloso-offset-top)'
        // el.style.top = '0';
        el.style.width = '100%'
        el.style.zIndex = '51'
    },
    onEnter: (el, done) => {
        const layers = pageTransitionWrapper.value.querySelectorAll('.page-transition-layer')
        const anim = animate(
            el,
            {
                transform: ['translateY(120vh) translateZ(0)', 'translateY(0vh) translateZ(0)'],
                zIndex: [51, 51],
            },
            {
                duration: 1,
                easing: [0.14, 0.47, 0.45, 0.94],
            },
        )
        anim.finished.then(() => {
            // debugger;
            el.removeAttribute('style')
            for (let i = 0, il = layers.length; i < il; ++i) {
                const layer = layers[i]
                layer.removeAttribute('style')
            }
            pageTransitionWrapper.value.removeAttribute('style')
            done()
        })
    },
}

/**
 * SEO META
 * <meta content="en_CA" property="og:locale">
 * <meta content="fr_CA" property="og:locale:alternate">
 * <MainLink href="https://geloso.com" rel="canonical">
 * <MainLink href="https://geloso.com/fr-ca" rel="alternate" hreflang="fr-ca">
 */
useHead({
    meta: [
        {
            property: 'og:locale', content: activeSite.value.lang,
        },
    ],
})

onMounted(() => {
    if (!isServer()) {
        setWindowSizes()
        loaded.value = true
        scrollTop.value = lenis.value.scroll
    }
})

/**
 * Mettre à jour les données des navigations et des globales
 * selon la langue du site sélectionné.
 *
 * @todo    Mettre à jour seulement au milieu de la transition de page pour éviter un "jump" pendant la navigation?
 */
const { data: dataNavs, refresh: refreshNavs } = await useAsyncData('dataNavs', () => GqlFetchNav({ lang: activeSite.value.lang }))
const { data: dataGlobals, refresh: refreshGlobals } = await useAsyncData('dataGlobals', () => GqlFetchGlobals({ lang: activeSite.value.lang }))

watch(activeSite, async () => {
    await refreshGlobals()
    await refreshNavs()

    setNavs(dataNavs.value)
    setGlobals(dataGlobals.value)

    // console.log(dataNavs);
})

setNavs(dataNavs.value)
setGlobals(dataGlobals.value)

</script>
<template>
    <div
        id="geloso-app"
        :class="offsetClasses"
    >
        <NuxtLayout>
            <NuxtPage :transition="pageTransitions" />
    <ClientOnly>
        <Loader />
    </ClientOnly>
        </NuxtLayout>
        <!-- <NuxtLoadingIndicator
            class="!fixed !bottom-0 !top-auto !border-t-3 !border-midblue"
            :height="3"
            color="#ffffff"
        /> -->
        <DevOnly>
            <PartialsDevIndices />
        </DevOnly>

        <div
            ref="pageTransitionWrapper"
            class="page-transition-wrapper size-screen pointer-events-none fixed left-0 top-0 z-50 translate-y-full"
            aria-hidden
        >
            <div class="page-transition-layer absolute left-0 top-0 size-full rounded-4xl bg-midblue" />
            <div class="page-transition-layer absolute left-0 top-10 size-full rounded-4xl bg-darkblue" />
        </div>
    </div>
</template>
