
// @ts-nocheck


export const localeCodes =  [
  "en-ca",
  "fr-ca",
  "en-us",
  "en-gb",
  "fr-fr"
]

export const localeLoaders = {
  "en-ca": [],
  "fr-ca": [],
  "en-us": [],
  "en-gb": [],
  "fr-fr": []
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    "en-ca",
    "fr-ca",
    "en-us",
    "en-gb",
    "fr-fr"
  ],
  "defaultLocale": "en-ca",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "en-ca",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "news/index": {
      "en-ca": "/news",
      "en-us": "/news",
      "en-gb": "/news",
      "fr-ca": "/nouvelles",
      "fr-fr": "/nouvelles"
    },
    "news/[slug]": {
      "en-ca": "/news/[slug]",
      "en-us": "/news/[slug]",
      "en-gb": "/news/[slug]",
      "fr-ca": "/nouvelles/[slug]",
      "fr-fr": "/nouvelles/[slug]"
    },
    "our-brands/[slug]": {
      "en-ca": "/our-brands/[slug]",
      "en-us": "/our-brands/[slug]",
      "en-gb": "/our-brands/[slug]",
      "fr-ca": "/nos-marques/[slug]",
      "fr-fr": "/nos-marques/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "language": "en-ca",
    "code": "en-ca"
  },
  {
    "language": "fr-ca",
    "code": "fr-ca"
  },
  {
    "language": "en-us",
    "code": "en-us"
  },
  {
    "language": "en-gb",
    "code": "en-gb"
  },
  {
    "language": "fr-fr",
    "code": "fr-fr"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
