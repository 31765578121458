<script setup>
const {
    loadingShown,
} = useClientState()
const loaderPosition = ref({ x: 0, y: 0 })
const targetPosition = ref({ x: 0, y: 0 })
const isVisible = ref(false)

/**
 * Updates the target position based on mouse movement
 * @param {MouseEvent} event - The mouse event object
 */
const updateTargetPosition = (event) => {
    targetPosition.value = {
        x: event.clientX + 12,
        y: event.clientY + 12
    }
}

/**
 * Animates the loader position with a smooth, laggy effect
 */
const animatePosition = () => {
    loaderPosition.value = {
        x: loaderPosition.value.x + (targetPosition.value.x - loaderPosition.value.x) * 0.1,
        y: loaderPosition.value.y + (targetPosition.value.y - loaderPosition.value.y) * 0.1
    }
    requestAnimationFrame(animatePosition)
}

/**
 * Watches loadingShown and updates isVisible with a delay
 */
watch(loadingShown, (newValue) => {
    if (newValue) {
        isVisible.value = true
    } else {
        setTimeout(() => {
            isVisible.value = false
        }, 300) // Delay hiding to allow for transition
    }
})

onMounted(() => {
    window.addEventListener('mousemove', updateTargetPosition)
    animatePosition()
})

onUnmounted(() => {
    window.removeEventListener('mousemove', updateTargetPosition)
})
</script>
<template>
    <Transition enter-active-class="transition-opacity duration-300 ease-out" enter-from-class="opacity-0"
        enter-to-class="opacity-100" leave-active-class="transition-opacity duration-300 ease-in"
        leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-show="isVisible" class="lg:left-[var(--loader-x)] lg:top-[var(--loader-y)] top-auto left-auto bottom-4 right-4 lg:bottom-auto lg:right-auto w-4 h-4 fixed z-100 pointer-events-none"
            :style="{
                '--loader-x': `${loaderPosition.x}px`,
                '--loader-y': `${loaderPosition.y}px`
            }">
            <svg class="animate-spin h-full w-full" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path class="text-midblue"
                    d="M8.77729 4.67583C5.96396 5.91554 4 8.72866 4 12.0002H0C0 7.09292 2.94596 2.87325 7.16595 1.01367L8.77729 4.67583Z"
                    fill="currentColor" />
                <path class="text-midblue/40"
                    d="M4 12H0C0 18.627 5.37256 24 12 24C18.6274 24 24 18.627 24 12C24 5.37305 18.6274 0 12 0C10.2802 0 8.64482 0.361832 7.16595 1.01351L8.77729 4.67567C9.76321 4.24122 10.8534 4 12 4C16.4183 4 20 7.58203 20 12C20 16.418 16.4183 20 12 20C7.58167 20 4 16.418 4 12Z"
                    fill="currentColor" />
            </svg>
        </div>
    </Transition>
</template>