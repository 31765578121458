import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91slug_93ptAvDB1DooMeta } from "/opt/buildhome/repo/pages/news/[slug].vue?macro=true";
import { default as indexAbjrqDDD8FMeta } from "/opt/buildhome/repo/pages/news/index.vue?macro=true";
import { default as _91slug_936JJhRb0GJdMeta } from "/opt/buildhome/repo/pages/our-brands/[slug].vue?macro=true";
export default [
  {
    name: "slug___en-ca",
    path: "/:slug(.*)*/",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "slug___fr-ca",
    path: "/fr-ca/:slug(.*)*/",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "slug___en-us",
    path: "/en-us/:slug(.*)*/",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "slug___en-gb",
    path: "/en-gb/:slug(.*)*/",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "slug___fr-fr",
    path: "/fr-fr/:slug(.*)*/",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "index___en-ca",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___fr-ca",
    path: "/fr-ca/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___en-us",
    path: "/en-us/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___en-gb",
    path: "/en-gb/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___fr-fr",
    path: "/fr-fr/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "news-slug___en-ca",
    path: "/news/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue")
  },
  {
    name: "news-slug___fr-ca",
    path: "/fr-ca/nouvelles/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en-us",
    path: "/en-us/news/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en-gb",
    path: "/en-gb/news/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue")
  },
  {
    name: "news-slug___fr-fr",
    path: "/fr-fr/nouvelles/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/news/[slug].vue")
  },
  {
    name: "news___en-ca",
    path: "/news/",
    component: () => import("/opt/buildhome/repo/pages/news/index.vue")
  },
  {
    name: "news___fr-ca",
    path: "/fr-ca/nouvelles/",
    component: () => import("/opt/buildhome/repo/pages/news/index.vue")
  },
  {
    name: "news___en-us",
    path: "/en-us/news/",
    component: () => import("/opt/buildhome/repo/pages/news/index.vue")
  },
  {
    name: "news___en-gb",
    path: "/en-gb/news/",
    component: () => import("/opt/buildhome/repo/pages/news/index.vue")
  },
  {
    name: "news___fr-fr",
    path: "/fr-fr/nouvelles/",
    component: () => import("/opt/buildhome/repo/pages/news/index.vue")
  },
  {
    name: "our-brands-slug___en-ca",
    path: "/our-brands/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___fr-ca",
    path: "/fr-ca/nos-marques/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___en-us",
    path: "/en-us/our-brands/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___en-gb",
    path: "/en-gb/our-brands/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/our-brands/[slug].vue")
  },
  {
    name: "our-brands-slug___fr-fr",
    path: "/fr-fr/nos-marques/:slug()/",
    component: () => import("/opt/buildhome/repo/pages/our-brands/[slug].vue")
  }
]